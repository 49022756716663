import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 11,
    title: "Ottimizzazione delle Prestazioni in React Native",
    desc: "Strategie e Pratiche Consigliate.",
    img: "/blog-image/prestazioniReact.jpg",
    page: "blog/prestazioni_react",
    data: "4 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "React Native è uno dei framework più popolari per lo sviluppo di app mobili cross-platform.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        React Native è uno dei framework più
                                        popolari per lo sviluppo di app mobili
                                        cross-platform. Tuttavia, con l'aumento
                                        delle dimensioni e della complessità
                                        delle app, è fondamentale affrontare il
                                        tema dell'ottimizzazione delle
                                        prestazioni. In questo articolo,
                                        esploreremo strategie e pratiche
                                        consigliate per ottimizzare le
                                        prestazioni delle tue app React Native,
                                        concentrandoci su aspetti cruciali come
                                        il rendering, la memorizzazione e il
                                        lazy loading.
                                    </p>
                                    <h3>1. Ottimizzazione del Rendering</h3>
                                    <p>
                                        Il rendering è un aspetto critico per le
                                        prestazioni delle app React Native. Il
                                        framework utilizza un Virtual DOM per
                                        gestire gli aggiornamenti
                                        dell'interfaccia utente in modo
                                        efficiente. Tuttavia, è importante
                                        adottare alcune pratiche per ottimizzare
                                        ulteriormente questo processo:
                                    </p>
                                    <p>
                                        <b>
                                            Utilizzo del PureComponent o di
                                            React.memo()
                                        </b>{" "}
                                        : questi componenti sono in grado di
                                        evitare un inutile rendering.
                                        PureComponent effettua un controllo di
                                        shallow equality tra le proprietà
                                        (props) precedenti e attuali, mentre
                                        React.memo() offre un controllo
                                        personalizzato delle prop per la
                                        memoizzazione.
                                    </p>
                                    <p>
                                        <b>Key Uniche</b>: c’è da assciurarsi
                                        che ogni elemento all'interno di un
                                        array abbia una chiave unica. Questo
                                        consente a React di ottimizzare il
                                        processo di aggiornamento.
                                    </p>
                                    <p>
                                        <b>
                                            Utilizzo di FlatList e SectionList
                                        </b>{" "}
                                        : questi componenti ottimizzati sono
                                        particolarmente utili quando si tratta
                                        di visualizzare grandi quantità di dati.
                                        Essi caricano dinamicamente solo gli
                                        elementi visibili nell'interfaccia
                                        utente, riducendo il carico sul
                                        rendering.
                                    </p>
                                    <h3>2. Memorizzazione</h3>
                                    <p>
                                        La memorizzazione è una tecnica cruciale
                                        per evitare il ricalcolo e il rendering
                                        inutili di componenti e funzioni. In
                                        React Native è possibile sfruttarla in
                                        diversi modi:
                                    </p>
                                    <p>
                                        <b>Memorizzazione dei Componenti</b> :
                                        oltre all'uso di PureComponent o
                                        React.memo(), si può utilizzare la hook
                                        `useMemo` per memorizzare il risultato
                                        di un componente in modo che venga
                                        calcolato solo quando le dipendenze
                                        cambiano.
                                    </p>
                                    <p>
                                        <b>Memorizzazione delle Funzioni</b> :
                                        utilizzare la hook `useCallback` per
                                        memorizzare le funzioni in modo che
                                        vengano create nuovamente solo se le
                                        dipendenze cambiano. Questo è
                                        particolarmente utile quando si passano
                                        funzioni ai componenti figlio.
                                    </p>
                                    <h3>3. Lazy Loading</h3>
                                    <p>
                                        <b>React.lazy()</b> : questa funzione
                                        consente di caricare dinamicamente un
                                        componente solo quando è richiesto. È
                                        utile per suddividere il bundle dell'app
                                        in parti più piccole, che possono essere
                                        caricate al momento opportuno.
                                    </p>
                                    <p>
                                        <b>Dynamic Import</b> : per le risorse
                                        non componenti, è possibile utilizzare
                                        il dynamic import per caricare i file
                                        solo quando sono necessari. Ad esempio,
                                        puoi caricare immagini o dati JSON solo
                                        quando vengono visualizzati in una
                                        schermata specifica.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h3>4. Ottimizzazione dello Stato</h3>
                                    <p>
                                        La gestione dello stato è fondamentale
                                        nelle app React Native. Tuttavia, uno
                                        stato mal gestito può portare a
                                        re-renders inutili e prestazioni
                                        scadenti. Ecco alcuni suggerimenti:
                                    </p>
                                    <p>
                                        La gestione dello stato è fondamentale
                                        nelle app React Native. Tuttavia, uno
                                        stato mal gestito può portare a
                                        re-renders inutili e prestazioni
                                        scadenti. Ecco alcuni suggerimenti:
                                    </p>
                                    <p>
                                        <b>Utilizzo dello Stato Locale</b> :
                                        quando possibile, utilizzare lo stato
                                        locale per i componenti - questo evita
                                        il ricalcolo e il rendering di
                                        componenti non correlati.
                                    </p>
                                    <p>
                                        <b>State Immutabile</b> : mantieni
                                        l'immutabilità dello stato utilizzando
                                        metodi come `setState` con oggetti o
                                        funzioni che restituiscono nuovi oggetti
                                        anziché modificare direttamente lo stato
                                        esistente.
                                    </p>
                                    <h3>5. Ottimizzazione delle Immagini</h3>
                                    <p>
                                        Le immagini sono spesso una fonte comune
                                        di rallentamenti nelle app mobili. Ecco
                                        alcune pratiche per ottimizzarle:
                                    </p>
                                    <p>
                                        <b>Ridimensionamento delle Immagini</b>{" "}
                                        : assicurati che le dimensioni delle
                                        immagini siano adeguate alle dimensioni
                                        visualizzate. Puoi utilizzare librerie
                                        come `react-native-image-resizer` per
                                        ridimensionare le immagini
                                        dinamicamente.
                                    </p>
                                    <p>
                                        <b>Compressione</b> : È consigliabile
                                        comprimere le immagini senza
                                        compromettere troppo la qualità. In
                                        questo modo si ridurrà il carico della
                                        rete e l'utilizzo della memoria.
                                    </p>
                                    <h3>6. Strumenti di Profilazione</h3>
                                    <p>
                                        Infine, non bisogna dimenticarsi di
                                        utilizzare gli strumenti di profilazione
                                        forniti da React Native per
                                        l’identificazione delle aree
                                        problematiche e la misurazione delle
                                        prestazioni dell'app:
                                    </p>
                                    <p>
                                        <b>React DevTools</b> : questi strumenti
                                        consentono di esaminare il Virtual DOM,
                                        monitorare gli aggiornamenti e
                                        identificare i componenti che causano
                                        re-renders inutili.
                                    </p>
                                    <p>
                                        <b>Performance Monitor</b> : utilizza il
                                        performance monitor incluso in React
                                        Native Debugger per analizzare il tempo
                                        di rendering, la frequenza di fotogrammi
                                        e altri dati critici.
                                    </p>
                                    <p>
                                        L’ottimizzazione delle prestazioni delle
                                        app React Native è un processo
                                        impegnativo ma cruciale. Queste pratiche
                                        e strategie consigliate mirano a
                                        garantire un’esecuzione fluida ed
                                        efficiente della tua app - non
                                        tralasciando la qualità dell’esperienza
                                        utente. Per ogni esigenza legata
                                        all’attuale o al tuo progetto di app
                                        React Native contattaci! Buono sviluppo
                                        a tutti.
                                    </p>

                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
